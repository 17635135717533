import React, { Fragment } from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404: Not found" />
    <div style={{ textAlign: 'center' }}>
      <h1>Page non trouvée</h1>
      <p>Cette page n'existe pas...</p>
    </div>
  </Fragment>
)

export default NotFoundPage
